import React, { FC, useEffect, useMemo } from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import LoginPage from '../../pages/LoginPage'
import PasswordRemindPage from '../../pages/PasswordRemindPage'
import RegisterPage from '../../pages/RegisterPage'
import Gallery from '../../pages/Gallery'
import GalleryItem from '../../pages/GalleryItem'
import Agenda from '../../pages/Agenda'
import Map from '../../pages/Map'
import ExhibitorStand from '../../pages/ExhibitorStand'
import ExhibitorsList from '../../pages/ExhibitorsList'
import Transmission from '../../pages/Transmission'
import News from '../../pages/News'
import NewsItem from '../../pages/NewsItem'
import Networking from '../../pages/Networking'
import Notes from '../../pages/Notes'
import Access from '../../pages/Access'
import Contact from '../../pages/Contact'
import Materials from '../../pages/Materials'
import Favorites from '../../pages/Favorites'
import Profile from '../../pages/Profile'
import Lecturers from '../../pages/Lecturers'
import Lecturer from '../../pages/Lecturer'
import Welcome from '../../pages/Welcome'
import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { selectChangeRoute, selectUser, selectUserToken } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { selectEventConfiguration } from '../../redux/dashboard/selectors'
import NewPasswordPage from '../../pages/NewPasswordPage'
import translate from '../../translate'
import { Gamification } from '../../components/Gamification'
import { asyncFetchUserDetails } from '../../redux/user/async'
import { addToken } from '../../redux/user/slice'

// NOTE: paths like /items/:id must be above /items to work properly

const PrivateRoute = ({ component, isAuthenticated, isRouteEnabled = true, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated ? (
      isRouteEnabled ? (
        React.createElement(component, props)
      ) : (
        <>
          {dashboardMessage(DashboardMessageTypes.warning, translate('register_redirect'), 5)}
          <Redirect to={{ pathname: '/welcome' }} />
        </>
      )
    ) : !isAuthenticated ? (
      <Redirect to={{ pathname: '/logowanie' }} />
    ) : (
      <Redirect to={{ pathname: '/welcome' }} />
    )
  return <Route {...rest} render={routeComponent} />
}

const Routes: FC = () => {
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const event = useSelector(selectEventConfiguration)
  const isChangeRoute = useSelector(selectChangeRoute)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const userToken = location.search.split('?token=')[1]

  const NO_GAMIFICATION = useMemo(() => {
    return user?.user_groups1?.some(
      (element) => element.name === 'Prelegenci' || element.name === 'Online',
    )
  }, [user])

  const authenticateUser = () => {
    return (Boolean(user) && Boolean(token)) || Boolean(userToken)
  }

  const authenticateEvent = () => {
    return user?.registered_events.some((obj) => obj.slug === event?.slug)
  }

  useEffect(() => {
    if (userToken && pathname !== '/nowe-haslo') {
      dispatch(addToken(userToken))

      dispatch(
        asyncFetchUserDetails({
          additionalHeaders: { Authorization: `JWT ${userToken}` },
          isError: () => history.push('/logowanie'),
          token: userToken,
        }),
      )
    }
  }, [userToken])

  useEffect(() => {
    if (isChangeRoute) history.push(pathname)
  }, [isChangeRoute])

  return (
    // <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Redirect to="/logowanie" />
      </Route>

      <Route path="/logowanie" component={LoginPage} />
      <Route path="/przypomnij-haslo" component={PasswordRemindPage} />
      <Route path="/rejestracja" component={RegisterPage} />
      <Route path="/nowe-haslo" component={NewPasswordPage} />

      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={!NO_GAMIFICATION}
        path="/gamification"
        component={Gamification}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/agenda"
        component={Agenda}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/event/:event/gallery/:id"
        component={GalleryItem}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/gallery"
        component={Gallery}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/exhibitors/:id"
        component={ExhibitorStand}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/map"
        component={Map}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/exhibitors"
        component={ExhibitorsList}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/transmission/:id"
        component={Transmission}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/transmission"
        component={Transmission}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/article/:id"
        component={NewsItem}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/articles/:id"
        component={News}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent() && event?.networking_enabled}
        path="/event/:event/networking"
        component={Networking}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/access"
        component={Access}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/contact"
        component={Contact}
      />
      <PrivateRoute isAuthenticated={authenticateUser()} path="/notes" component={Notes} />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/people/:id"
        component={Lecturer}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        isRouteEnabled={authenticateEvent()}
        path="/event/:event/people"
        component={Lecturers}
      />
      <PrivateRoute isAuthenticated={authenticateUser()} path="/materialy" component={Materials} />
      <PrivateRoute isAuthenticated={authenticateUser()} path="/ulubione" component={Favorites} />
      <PrivateRoute isAuthenticated={authenticateUser()} path="/profil" component={Profile} />
      <PrivateRoute isAuthenticated={authenticateUser()} path="/welcome" component={Welcome} />
      {/* <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/add-event"
        component={AddEventPayments}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/add-event-finished"
        component={AddEventFinished}
      />
      <PrivateRoute
        isAuthenticated={authenticateUser()}
        path="/add-event-form"
        component={AddEventForm}
      /> */}
    </Switch>
    // </BrowserRouter>
  )
}

export default Routes
