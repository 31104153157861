import React, { useEffect, useRef, useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPeopleNetworking, selectSlug } from '../../redux/dashboard/selectors'
import { LoadingOutlined } from '@ant-design/icons'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { asyncFetchPeopleNetworking } from '../../redux/dashboard/async'
import NetworkingInput from '../NetworkingInput'
import userImage from '../../../images/user.png'
import NetworkingMessage from '../NetworkingMessage'
import { Spin } from 'antd'

type Props = {
  id: number
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const ChatAPI = require('twilio-chat')

const InfoBarChat: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const user = useSelector(selectUser)
  const userToken = useSelector(selectUserToken)
  const peopleNetworking = useSelector(selectPeopleNetworking)
  const [activeChat] = useState<string | null>(`${slug}-agenda_item-${id}`)

  const [, setRoom] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<any[]>([])
  const [channel, setChannel] = useState<any>(undefined)

  let token = { token: '' }
  let scrollDiv = useRef<any>(null)

  const findPersonById = (id: number) => {
    if (!id) {
      return {
        firstname: 'Nieznany',
        lastname: 'Użytkownik',
      }
    }
    return peopleNetworking.find((element: any) => Number(id) === Number(element.id))
  }

  useEffect(() => {
    if (slug !== undefined) {
      dispatch(
        asyncFetchPeopleNetworking({
          slug: slug,
          additionalHeaders: { Authorization: `JWT ${userToken}` },
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const tryJoinChannel = async () => {
    setLoading(true)
    try {
      token = await getToken(user?.id)
      // console.log(token)
    } catch {
      throw new Error('Unable to get token, please reload this page')
    }

    const client = await ChatAPI.Client.create(token.token)

    client.on('tokenAboutToExpire', async () => {
      const token = await getToken(user?.id)
      client.updateToken(token)
    })

    client.on('tokenExpired', async () => {
      const token = await getToken(user?.id)
      client.updateToken(token)
    })

    client.on('channelJoined', async (channel: any) => {
      console.log('client joined')
      scrollToBottom()
    })

    // if (firebase) {
    //   const config = {
    //     apiKey: 'AIzaSyAVM7aAJbKoYdQTlkyjHf1Bt9kADGxtZVs',

    //     authDomain: 'twilio-escola.firebaseapp.com',

    //     databaseURL: 'https://twilio-escola-default-rtdb.europe-west1.firebasedatabase.app',

    //     projectId: 'twilio-escola',

    //     storageBucket: 'twilio-escola.appspot.com',

    //     messagingSenderId: '131048634668',

    //     appId: '1:131048634668:web:020a22a169869c0b64d489',
    //   }
    //   firebase.initializeApp(config)
    //   // const messaging = firebase.messaging()
    //   // console.log(messaging)
    //   // requesting permission to use push notifications
    //   firebase
    //     .messaging()
    //     .requestPermission()
    //     .then(() => {
    //       // getting FCM token
    //       firebase
    //         .messaging()
    //         .getToken()
    //         .then((fcmToken) => {
    //           // passing FCM token to the `chatClientInstance` to register for push notifications
    //           client.setPushRegistrationId('fcm', fcmToken)
    //           // registering event listener on new message from firebase to pass it to the Chat SDK for parsing
    //           firebase.messaging().onMessage((payload) => {
    //             client.handlePushNotification(payload)
    //           })
    //         })
    //         .catch((err) => {
    //           console.log(err, 'cant get token')
    //           // can't get token
    //         })
    //     })
    //     .catch((err) => {
    //       console.log(err, 'permission')
    //       // can't request permission or permission hasn't been granted to the web app by the user
    //     })
    // } else {
    //   console.log('no firebase')
    //   // no Firebase library imported or Firebase library wasn't correctly initialized
    // }

    try {
      const channel = await client.getChannelByUniqueName(getRoomId(Number(user?.id), activeChat))
      joinChannel(channel)
      setChannel(channel)
    } catch (err) {
      try {
        const channel = await client.createChannel({
          uniqueName: getRoomId(Number(user?.id), activeChat),
          friendlyName: getRoomId(Number(user?.id), activeChat),
        })

        joinChannel(channel)
      } catch {
        throw new Error('Unable to create channel, please reload this page')
      }
    }
  }

  const getRoomId = (a: number | string, b: string | number | null) => {
    return activeChat
  }

  const joinChannel = async (channel: any) => {
    if (channel.channelState.status !== 'joined') {
      await channel.join()
    }

    setChannel(channel)

    if (channel.channelState.status === 'joined') {
      const newMessages = await channel.getMessages()
      setMessages(newMessages.items || [])
    }
    channel.on('messageAdded', function(message: any) {
      handleMessageAdded(message)
    })
    setLoading(false)
    scrollToBottom()
  }

  const getToken = async (user_identity = 1, room_name = 'pokoj_test') => {
    const endpoint = 'https://meetinga-twilio-server.etd24.pl/token/'

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        user_identity,
        room_name,
        create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
      }),
    }).then((res) => res.json())
  }

  const handleMessageAdded = (message: any) => {
    setMessages((messages) => [...messages, message])
    scrollToBottom()
  }

  const scrollToBottom = () => {
    scrollDiv.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  const sendMessage = (text: string) => {
    if (text) {
      channel.sendMessage(String(text)?.trim())
    }
  }

  useEffect(() => {
    if (activeChat !== null) {
      setRoom(getRoomId(Number(user?.id), activeChat))
      tryJoinChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat])

  return activeChat ? (
    <div className="">
      {/* <div className="main__top main__top--networking">
        <MainTitle className="main__title--networking" title={translate('networking')} />
      </div> */}
      <div className="networking-messages">
        {loading && (
          <div className="spinner-wrapper">
            <Spin indicator={antIcon} />
          </div>
        )}

        {messages &&
          !loading &&
          messages.map((message: any) => {
            return (
              <NetworkingMessage
                key={message.index}
                isOnline={findPersonById(message.author)?.isOnline}
                date={message.state.timestamp}
                img={
                  Number(user?.id) === Number(message.author)
                    ? user?.photo_url || userImage
                    : findPersonById(message.author)?.photo_url || userImage
                }
                name={
                  Number(user?.id) === Number(message.author)
                    ? `${user?.firstname} ${user?.lastname}`
                    : `${findPersonById(message.author)?.firstname} ${
                        findPersonById(message.author)?.lastname
                      }`
                }
                message={message.body}
                type={Number(user?.id) === Number(message.author) ? 'currentUser' : 'otherUser'}
              />
            )
          })}
        <div ref={scrollDiv}></div>
      </div>

      <NetworkingInput onClick={sendMessage} isActive={Boolean(activeChat) && Boolean(!loading)} />
    </div>
  ) : null
}

export default InfoBarChat
