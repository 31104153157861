import React, { FC, useEffect, useState } from 'react'
import useSWR from 'swr'
import { GAMIFICATION_URL } from '../../../../config'
import { fetcher } from '../../../services/api'
import { CurrentExhibitorProps } from '..'
import LoginInput from '../../LoginInput'
import dashboardMessage from '../../Message'
import { DashboardMessageTypes } from '../../../utils/enums'

type GamificationExhibitorProps = {
  id: number
  token: string
  currentExhibitor: CurrentExhibitorProps
}
type GamificationExhibitorSWRProps = {
  result: number
  payload: {
    id: number
    name: string
    points: number
    code: string
  }
}

export const GamificationExhibitor: FC<GamificationExhibitorProps> = ({
  id,
  token,
  currentExhibitor,
}) => {
  const { data, error, isLoading, mutate } = useSWR<GamificationExhibitorSWRProps>(
    `${GAMIFICATION_URL}/exhibitor/${id}`,
    (url) => fetcher(url, token),
  )

  const [exhibitorForm, setExhibitorForm] = useState({
    id: id,
    code: 'Brak',
    points: 0,
  })

  useEffect(() => {
    if (data) {
      setExhibitorForm({
        id: data.payload.id,
        code: data.payload.code,
        points: data.payload.points,
      })
    }
  }, [data])

  if (isLoading) return <div>Wczytywanie wystawcy</div>

  if (error)
    dashboardMessage(DashboardMessageTypes.error, 'Coś poszło nie tak z wczytywaniem wystawcy.')

  if (data) {
    return (
      <div className="gamification-wrapper">
        <div className="gamification-wrapper">
          <span className="gamification-send-code-text">
            Wprowadź punkty i kod dla wystawcy: {currentExhibitor.name}
          </span>

          <span className="gamification-send-code-subtext">
            Aktualny kod wystawcy:{' '}
            <strong style={{ color: '#ee312f' }}>{data?.payload.code || 'Brak'}</strong>
          </span>
          <span className="gamification-send-code-subtext">
            Aktualne punkty wystawcy:{' '}
            <strong style={{ color: '#ee312f' }}>{data?.payload.points || 0}</strong>
          </span>

          <LoginInput
            label="Kod"
            name="code"
            placeholder="Proszę wpisać 6 znaków oraz kod, który nie został jeszcze nigdzie użyty"
            type="text"
            onChange={setExhibitorForm}
          />

          <LoginInput
            label="Punkty"
            name="points"
            placeholder="Liczba punktów"
            type="text"
            onChange={setExhibitorForm}
          />
          <button
            className="gamification-button gamification-button--form"
            onClick={() =>
              fetcher(`${GAMIFICATION_URL}/exhibitor/update`, token, 'PATCH', {
                ...exhibitorForm,
                points: Number(exhibitorForm.points),
              })
                .finally(() => mutate())
                .then((response) => {
                  if (response.result === 1) {
                    dashboardMessage(
                      DashboardMessageTypes.success,
                      'Wysłano pomyślnie! Punkty oraz kod wystawcy zostały zmienione.',
                    )
                  } else {
                    dashboardMessage(
                      DashboardMessageTypes.error,
                      'Coś poszło nie tak, czy na pewno podałeś prawidłowy kod oraz ilość punktów?',
                    )
                  }
                })
            }
          >
            Wyślij
          </button>
        </div>
      </div>
    )
  }

  return null
}
