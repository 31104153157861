import axios from 'axios'
import { EVENT_URL, LANGUAGE, HEADERS } from './consts'

export const apiEvent = (slug: string, additionalHeaders?: any, language?: string) => {
  const lang = language || localStorage.getItem('lang') || LANGUAGE

  return axios.create({
    baseURL: `${EVENT_URL}/${slug}/${lang}/api`,
    headers: {
      ...HEADERS,
      ...additionalHeaders,
    },
  })
}

export const apiEvents = (additionalHeaders?: any) => {
  const lang = localStorage.getItem('lang') || LANGUAGE

  return axios.create({
    baseURL: `${EVENT_URL}/${lang}/api`,
    headers: {
      ...HEADERS,
      ...additionalHeaders,
    },
  })
}

export const fetcher = (
  url: string,
  token: string,
  method?: string,
  body?: {
    [key: string]: string | number
  },
  contentType?: string,
  text?: boolean,
) => {
  if (body) {
    return fetch(url, {
      method: method ? method : 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        accept: '*/*',
        'Content-Type': contentType ? contentType : 'application/json',
      },
      body: body ? JSON.stringify(body) : '',
    }).then((res) => (contentType ? res : res.json()))
  }

  return fetch(url, {
    method: method || 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      accept: '*/*',
      'Content-Type': contentType ? contentType : 'application/json',
    },
  }).then((res) => (text ? res.text() : res.json()))
}

export const fetcherDashboard = (url: string, token: string, additionalHeaders: any) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      ...HEADERS,
      ...additionalHeaders,
    },
  }).then((res) => res.json())
}
