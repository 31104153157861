import React from 'react'
import Dashboard from '../../views/Dashboard'
import logo from '../../../images/marketing_technology-2.png'

const Welcome: React.FC = () => {
  return (
    <Dashboard activePage="welcome">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="welcome__wrapper">
            <img src={logo} alt="Meetinga" />
            <h1>O konferencji</h1>

            <p className="welcome__p">
              Od <strong>16 do 18 kwietnia 2024 roku</strong> odbędzie się już XVII edycja
              konferencji <strong>I ❤️ Marketing&Technology</strong>! Konferencja potrwa aż trzy
              dni, a my przygotowaliśmy na każdy dzień jedną ścieżkę tematyczną:{' '}
              <strong>I ❤️ social media</strong>, <strong>I ❤️ marketing</strong> oraz nowość w
              języku polskim: <strong>I ❤️ AI.</strong> Będziesz mieć okazję poznać najnowsze
              narzędzia, trendy, strategie i innowacje. Dowiesz się, jak wykorzystać potencjał
              sztucznej inteligencji w marketingu oraz tego, jak wdrożyć AI w swojej firmie.
            </p>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Welcome
