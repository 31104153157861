import React, { FC } from 'react'
import '../styles.scss'
import useSWR from 'swr'
import { fetcher, fetcherDashboard } from '../../../services/api'
import { GAMIFICATION_URL } from '../../../../config'
import { GamificationSendCode } from '../GamificationSendCode'
import { GamificationTitle } from '../GamificationTitle'
import './styles.scss'
import { GamificationFetchSlugProps } from '../GamificationAdmin'
import ExhibitorDefaultLogo from '../../../../images/building.svg'
import { EVENT_URL } from '../../../services/consts'
import { format } from 'date-fns'

type GamificationExhibitorListProps = {
  userId: number
  token: string
}

export type GamificationUserExhibitorListFetchProps = {
  result: number
  payload: {
    id: number
    name: string
    voted: boolean
  }[]
  statusCode?: number
}

export const rewards = [
  'Bilety VIP na kolejną konferencję, 1000, 500 i 300 zł na szkolenia oraz prenumeraty od sprawny.marketing',
  'Budżet reklamowy (800 zł, 600 zł, 500 zł) od Viral Talk',
  '3 dni darmowej diety od Body Chief',
  `Produkcja wideocastu od OMG Prime Studio`,
  'Media recording kits od Clever Frame',
  'Content Plan i gadżety od Trust Luna',
  'Vouchery na kawę z ekspertami i kostki rubika od Laurens Coster',
  'Darmowe przejazdy rowerami miejskimi od Nextbike',
  'Albumy Taschen (All American Ads) od maxroy',
  '20% rabatu na 6 miesięcy usługi od Onepage',
  'PowerBoxy od Happy Gifts Europe',
  'Kubki od Agencji Szpilka',
  'Bilety kinowe do Multikina',
  'Książki biznesowe',
]

export const GamificationUserExhibitorList: FC<GamificationExhibitorListProps> = ({
  userId,
  token,
}) => {
  const { data, error, isLoading, mutate } = useSWR<GamificationUserExhibitorListFetchProps>(
    `${GAMIFICATION_URL}/exhibitor/list/${userId}`,
    (url) => fetcher(url, token),
  )

  const { data: dataSlug } = useSWR<GamificationFetchSlugProps>(
    `${GAMIFICATION_URL}/exhibitor/current-event`,
    (url) => fetcher(url, token),
  )

  const { data: dataEvent } = useSWR<any>(
    `${EVENT_URL}/${dataSlug?.payload}/pl/api/events/content`,
    (url) => fetcherDashboard(url, token, { Authorization: `JWT ${token}` }),
  )

  // {{meetinga_url}}/{{event_slug}}/{{language}}/api/events/content/

  // const { data: dataSlug } = useSWR<GamificationFetchSlugProps>(
  //   `${GAMIFICATION_URL}/exhibitor/current-event`,
  //   (url) => fetcher(url, token),
  // )

  // const exhibitors = useSelector(selectExhibitors)

  if (error) {
    return <div className="gamification-wrapper">Coś poszło nie tak z wczytywaniem wystawców.</div>
  }

  if (data?.statusCode === 403) {
    return (
      <div className="gamification-wrapper">
        <GamificationTitle title="Weź udział w grywalizacji i wygraj atrakcyjne nagrody!" />

        <div className="gamification-info">
          <span className="gamification-send-code-subtext">
            1. Wejdź w zakładkę Wystawcy i sprawdź, jakie zdanie przygotował każdy Partner.
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            2. Odwiedzaj stoiska, wykonuj zadania, a następnie wpisuj kody od wystawców poniżej.
            Każdy Partner ma swój unikalny kod na każdy dzień.
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            3. Zbieraj punkty i zdobywaj atrakcyjne nagrody.
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            4. Na koniec każdego dnia ogłosimy 5 najlepszych uczestniczek/uczestników ze sceny.
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>*</strong> Nie udało Ci się dzisiaj? Spróbuj jutro!
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>**</strong> Jeśli zostałaś/eś już nagrodzony, daj
            szansę innym. Wyróżnionym można zostać maksymalnie raz.
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>***</strong> W przypadku takiej samej liczby punktów
            decydujący będzie czas realizacji zadań (od momentu wpisania pierwszego kodu do
            ostatniego).
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>****</strong> Zadania można realizować do 17:30.
          </span>
          <span className="gamification-send-code-text">Do wygrania m.in:</span>
          <ul className="gamification-info-list">
            {rewards.map((element) => {
              return <li className="gamification-send-code-subtext">{element}</li>
            })}
          </ul>
        </div>

        <span className="gamification-send-code-text">
          Żeby wpisać kod wystawcy musisz być zarejestrowany na bieżące wydarzenie grywalizacji!{' '}
          <br />
          Czy na pewno jesteś uczestnikiem aktualnego wydarzenia {dataSlug?.payload}?
        </span>
      </div>
    )
  }

  if (data)
    return (
      <>
        <div className="gamification-wrapper">
          <GamificationTitle title="Weź udział w grywalizacji i wygraj atrakcyjne nagrody!" />

          <div className="gamification-info">
            <span className="gamification-send-code-subtext">
              1. Wejdź w zakładkę Wystawcy i sprawdź, jakie zdanie przygotował każdy Partner.
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              2. Odwiedzaj stoiska, wykonuj zadania, a następnie wpisuj kody od wystawców poniżej.
              Każdy Partner ma swój unikalny kod na każdy dzień.
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              3. Zbieraj punkty i zdobywaj atrakcyjne nagrody.
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              4. Na koniec każdego dnia ogłosimy 5 najlepszych uczestniczek/uczestników ze sceny.
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>*</strong> Nie udało Ci się dzisiaj? Spróbuj jutro!
              Zadania będą inne.
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>**</strong> Jeśli zostałaś/eś już nagrodzony, daj
              szansę innym. Wyróżnionym można zostać maksymalnie raz.
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>***</strong> W przypadku takiej samej liczby punktów
              decydujący będzie czas realizacji zadań (od momentu wpisania pierwszego kodu do
              ostatniego).
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>****</strong> Zadania można realizować do 17:30.
            </span>
            <span className="gamification-send-code-text">Do wygrania m.in:</span>
            <ul className="gamification-info-list">
              {rewards.map((element) => {
                return <li className="gamification-send-code-subtext">{element}</li>
              })}
            </ul>
          </div>

          <GamificationSendCode userId={userId} token={token} fetchUserExhibitorList={mutate} />

          <div className="gamification-tiles-wrapper">
            {data &&
              data.payload &&
              data.payload.map((element, index) => {
                return (
                  <div key={index} className="gamification-exhibitor-tile">
                    <div
                      className={`${
                        element.voted
                          ? 'gamification-exhibitor-tile-image-wrapper gamification-exhibitor-tile-image-wrapper--active'
                          : 'gamification-exhibitor-tile-image-wrapper'
                      }`}
                    >
                      <img
                        src={
                          dataEvent && dataEvent.map_points && dataEvent.map_points.length > 0
                            ? dataEvent.map_points.find((el: any) => el.id === element.id)
                              ? dataEvent.map_points.find((el: any) => el.id === element.id).logo
                              : ExhibitorDefaultLogo
                            : ExhibitorDefaultLogo
                        }
                        className="gamification-exhibitor-tile-image"
                      />
                    </div>
                    <div
                      className={`${
                        element.voted
                          ? 'gamification-exhibitor-is-voted gamification-exhibitor-is-voted--active'
                          : 'gamification-exhibitor-is-voted'
                      }`}
                    >
                      <span>
                        {element.name === 'Orion media' ? 'Orion Media Group' : element.name}
                      </span>

                      <span>{element.voted ? 'Zagłosowano' : 'Nie zagłosowano'}</span>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </>
    )
  return null
}
