import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Dashboard from '../../views/Dashboard'
import MainTitle from '../../components/MainTitle'
import NavigationHeader from '../../components/NavigationHeader'

import { useSelector } from 'react-redux'
import { selectMenuItems, selectPeople } from '../../redux/dashboard/selectors'
import { cutString } from '../../utils/functions'
import Person from '../../components/Person'
import translate from '../../translate'
import { PeopleProps } from '../../redux/dashboard/interfaces'

const Lecturers: FC = () => {
  const title = useSelector(selectMenuItems)
  const people = useSelector(selectPeople)

  const sortedPeople = JSON.parse(JSON.stringify(people)).sort((a: PeopleProps, b: PeopleProps) => {
    const nameA = `${a.firstname.toUpperCase()} ${a.lastname.toUpperCase()}`
    const nameB = `${b.firstname.toUpperCase()} ${b.lastname.toUpperCase()}`
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  const getTitleName = () => {
    return title?.find((element) => element.type === 'People')?.title
  }

  return (
    <Dashboard activePage="people">
      <div className="main__container">
        <div className="main__wrapper">
          <NavigationHeader firstItem={getTitleName() || translate('people')} />
          <div className="main__top">
            <MainTitle title={getTitleName() || translate('people')} />
          </div>

          <div className="lecturers">
            {sortedPeople?.map((element: PeopleProps) => (
              <Link className="lecturer-person" key={element.id} to={`people/${element.id}`}>
                <Person
                  img={element.photo_url}
                  name={`${element.firstname} ${element.lastname}`}
                  title={element.post}
                  status={cutString(element.short_content || '', 64)}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Lecturers
